import { render, staticRenderFns } from "./dashboard-events-form.vue?vue&type=template&id=9ea7f616&scoped=true&"
import script from "./dashboard-events-form.vue?vue&type=script&lang=js&"
export * from "./dashboard-events-form.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ea7f616",
  null
  
)

export default component.exports