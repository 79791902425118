var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('TogglePanel',{attrs:{"title":`${_vm.$tc('script', 2)} (${_vm.$tc('global', 1)})`,"icon":{
      before: 'fa fa-file-code-o',
      collapse: 'fa-caret-square-o-up',
      expand: 'fa-caret-square-o-down'
    }}},[_c('div',{staticClass:"text-center"},[_c('ScriptsForm')],1)]),_c('TogglePanel',{attrs:{"title":`${_vm.$tc('event', 2)} (${_vm.$tc('local', 1)})`,"icon":{
      before: 'fa fa-flash',
      collapse: 'fa-caret-square-o-up',
      expand: 'fa-caret-square-o-down'
    }}},[_c('AlertForm',{attrs:{"label":"require_confirmation_when_exiting","text":"you_have_unfinished_changes"},model:{value:(_vm.confirmation),callback:function ($$v) {_vm.confirmation=$$v},expression:"confirmation"}},[_c('template',{slot:"before"},[_c('div',{staticClass:"form-group form-group-sm"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$tc("trigger_condition", 1)))]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmationCondition),expression:"confirmationCondition"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.confirmationCondition=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"always"}},[_vm._v(_vm._s(_vm.$t("always")))]),_c('option',{attrs:{"value":"pending_changes"}},[_vm._v(_vm._s(_vm.$t("pending_changes")))])])])])],2),_c('div',[_c('ControlEventEditor',{staticStyle:{"margin":"10px -10px 0 -10px"},attrs:{"control":_vm.templateAsControl},on:{"delEvent":function($event){return _vm.onDelEvent($event)},"addEvent":function($event){return _vm.onAddEvent($event)}}})],1)],1),_c('TogglePanel',{attrs:{"title":"dataset","persistent":"toggle_dashboard_dataset","icon":{
      before: 'fa fa-database',
      collapse: 'fa-caret-square-o-up',
      expand: 'fa-caret-square-o-down'
    }}},[_c('NamedQueriesForm')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }